import { useMemo } from 'react'

import DatePicker from '../../Field/DatePicker/DatePicker'
import Dropzone from '../../Field/Dropzone/Dropzone'
import RadioGroup from '../../Field//RadioGroup/RadioGroup'
import { StyledCastingFieldHalf } from './styles'
import { StyledField } from '../styles'
import Input from '../../Field/Input/Input'
import Textarea from '../../Field/Textarea/Textarea'
import Select from '../../Field/Select/Select'
import { countries } from '../../../constants/countries'
import {
  requiredValidator,
  emailsEqualValidator,
} from '../../../common/validators'
import { makeInitialValues } from '../../../common/utils'

export const optionsCar = [
  {
    id: 'car-yes',
    label: 'Yes',
  },
  {
    id: 'car-no',
    label: 'No',
  },
]
export const optionsDriversLicense = [
  {
    id: 'driver-license-yes',
    label: 'Yes',
  },
  {
    id: 'driver-license-no',
    label: 'No',
  },
]

export const optionsCupSize = [
  {
    id: 'cup-size-natural',
    label: 'Natural',
  },
  {
    id: 'cup-size-enhanced',
    label: 'Enhanced',
  },
]

export const optionsExtensions = [
  {
    id: 'extensions-yes',
    label: 'Yes',
  },
  {
    id: 'extensions-no',
    label: 'No',
  },
]

export const optionsTattoos = [
  {
    id: 'tattoos-yes',
    label: 'Yes',
  },
  {
    id: 'tattoos-no',
    label: 'No',
  },
]

export const optionsPiercings = [
  {
    id: 'piercings-yes',
    label: 'Yes',
  },
  {
    id: 'piercings-no',
    label: 'No',
  },
]

export const optionsExperience = [
  {
    id: 'experience-yes',
    label: 'Yes',
  },
  {
    id: 'experience-no',
    label: 'No',
  },
]

export const optionsFormOfEmployment = [
  {
    id: 'form-of-employment-yes',
    label: 'Yes',
    dataRef: true,
  },
  {
    id: 'form-of-employment-no',
    label: 'No',
    dataRef: false,
  },
]

const startDate = new Date()

export const useFormFields = (isChecked, tabTitles, handleRadioChange) =>
  useMemo(
    () => {
      const fields = [
        {
          title: tabTitles[0],
          fields: [
            {
              wrapper: StyledCastingFieldHalf,
              component: Input,
              id: 'firstName',
              name: 'firstName',
              label: 'First Name',
              placeholder: 'Type your first name',
              validate: requiredValidator,
            },
            {
              wrapper: StyledCastingFieldHalf,
              component: Input,
              id: 'lastName',
              name: 'lastName',
              label: 'Last Name',
              placeholder: 'Type your last name',
              validate: requiredValidator,
            },
            {
              wrapper: StyledCastingFieldHalf,
              component: Input,
              id: 'email',
              name: 'email',
              label: 'Email',
              placeholder: 'Type your email',
              type: 'email',
              validate: requiredValidator,
            },
            {
              wrapper: StyledCastingFieldHalf,
              component: Input,
              id: 'confirmEmail',
              name: 'confirmEmail',
              label: 'Confirm Email',
              placeholder: 'Confirm your email',
              type: 'email',
              validate: emailsEqualValidator,
            },
            {
              wrapper: StyledCastingFieldHalf,
              component: Input,
              id: 'phone',
              name: 'phone',
              label: 'Phone',
              placeholder:
                'Enter phone number with country code e.g. ( +44 1234 567 890 ',
              validate: requiredValidator,
            },
            {
              wrapper: StyledCastingFieldHalf,
              component: DatePicker,
              id: 'birthOfDate',
              name: 'birthOfDate',
              label: 'Birth of date',
              initialValue: startDate,
            },
            {
              wrapper: StyledField,
              component: Input,
              id: 'address',
              name: 'address',
              label: 'Address',
              placeholder: 'Street and number',
              validate: requiredValidator,
            },
            {
              wrapper: StyledCastingFieldHalf,
              component: Input,
              id: 'city',
              name: 'city',
              label: 'City',
              placeholder: 'Type your city',
              validate: requiredValidator,
            },
            {
              wrapper: StyledCastingFieldHalf,
              component: Select,
              options: countries,
              id: 'country',
              name: 'country',
              label: 'Country',
              placeholder: 'Select your country',
              validate: requiredValidator,
            },
          ],
        },
        {
          title: tabTitles[1],
          fields: [
            {
              wrapper: StyledField,
              component: Input,
              id: 'nationality',
              name: 'nationality',
              label: 'Nationality',
              placeholder: 'Type your nationality',
              validate: requiredValidator,
            },
            {
              wrapper: StyledCastingFieldHalf,
              component: Input,
              id: 'motherTongue',
              name: 'motherTongue',
              label: 'Mother tongue',
              placeholder: 'Type your mother tongue',
              validate: requiredValidator,
            },
            {
              wrapper: StyledCastingFieldHalf,
              component: Input,
              id: 'otherLanguages',
              name: 'otherLanguages',
              label: 'Other languages',
              placeholder: 'Which other languages can you speak',
              validate: requiredValidator,
            },
            {
              wrapper: StyledField,
              component: RadioGroup,
              name: 'car',
              title: 'Do you have a car?',
              options: optionsCar,
              radioGroup: true,
            },
            {
              wrapper: StyledField,
              component: RadioGroup,
              name: 'driverLicense',
              title: 'Do you have your drivers license?',
              options: optionsDriversLicense,
              radioGroup: true,
            },
          ],
        },
        {
          title: tabTitles[2],
          fields: [
            {
              wrapper: StyledCastingFieldHalf,
              component: Input,
              id: 'hairColor',
              name: 'hairColor',
              label: 'Hair color',
              placeholder: 'Type your hair color',
              validate: requiredValidator,
            },
            {
              wrapper: StyledCastingFieldHalf,
              component: Input,
              id: 'eyeColor',
              name: 'eyeColor',
              label: 'Eye color',
              placeholder: 'Type your eye color',
              validate: requiredValidator,
            },
            {
              wrapper: StyledCastingFieldHalf,
              component: Input,
              id: 'weight',
              name: 'weight',
              label: 'Weight',
              placeholder: 'Weight in kg',
              validate: requiredValidator,
            },
            {
              wrapper: StyledCastingFieldHalf,
              component: Input,
              id: 'height',
              name: 'height',
              label: 'Height',
              placeholder: 'Height in cm',
              validate: requiredValidator,
            },
            {
              wrapper: StyledCastingFieldHalf,
              component: Input,
              id: 'dressSize',
              name: 'dressSize',
              label: 'Dress size',
              placeholder: 'Type your dress size',
              validate: requiredValidator,
            },
            {
              wrapper: StyledCastingFieldHalf,
              component: Input,
              id: 'lingerieSize',
              name: 'lingerieSize',
              label: 'Lingerie size',
              placeholder: 'Type your lingerie size',
              validate: requiredValidator,
            },
            {
              wrapper: StyledField,
              component: RadioGroup,
              name: 'cupSize',
              title: 'Cup size',
              options: optionsCupSize,
              radioGroup: true,
            },
            {
              wrapper: StyledField,
              component: RadioGroup,
              name: 'tattoos',
              title: 'Tattoos',
              options: optionsTattoos,
              radioGroup: true,
            },
          ],
        },
        {
          title: tabTitles[3],
          fields: [
            {
              wrapper: StyledCastingFieldHalf,
              component: Input,
              id: 'educationalLevel',
              name: 'educationalLevel',
              label: 'Educational Level',
              placeholder: 'Type your educational level',
              validate: requiredValidator,
            },
            {
              wrapper: StyledCastingFieldHalf,
              component: Input,
              id: 'professionOrStudies',
              name: 'professionOrStudies',
              label: 'Profession or Studies',
              placeholder: 'Type your profession or studies',
              validate: requiredValidator,
            },
            {
              wrapper: StyledField,
              component: Input,
              id: 'hobbies',
              name: 'hobbies',
              label: 'Hobbies',
              placeholder: 'Type your hobbies',
              validate: requiredValidator,
            },
            {
              wrapper: StyledField,
              component: Textarea,
              id: 'goalsInLife',
              label: 'Goals in life',
              name: 'goalsInLife',
              placeholder: 'Type your goals in life',
              validate: requiredValidator,
            },
            {
              wrapper: StyledField,
              component: Textarea,
              id: 'motivation',
              label: 'Motivation to join our agency',
              name: 'motivation',
              placeholder: 'Type your motivation to join our agency',
              validate: requiredValidator,
            },
          ],
        },
        {
          title: tabTitles[4],
          fields: [
            {
              wrapper: StyledField,
              component: RadioGroup,
              name: 'experience',
              title: 'Do you have experience as an escort model?',
              options: optionsExperience,
              radioGroup: true,
            },
            {
              wrapper: StyledField,
              component: RadioGroup,
              name: 'formOfEmployment',
              title: 'Do you work as an independent or with another agency?',
              options: optionsFormOfEmployment,
              radioGroup: true,
              onChange: handleRadioChange,
            },
            {
              wrapper: StyledField,
              component: Textarea,
              id: 'websites',
              label: 'Please add websites where you are listed',
              name: 'websites',
              placeholder: 'Please add websites where you are listed',
              disabled: !isChecked,
            },
          ],
        },
        {
          title: tabTitles[5],
          fields: [
            {
              wrapper: StyledField,
              component: Input,
              id: 'daysAvailable',
              label: 'Days available',
              name: 'daysAvailable',
              placeholder: 'Type your available days',
              validate: requiredValidator,
            },
            {
              wrapper: StyledField,
              component: Input,
              id: 'hoursAvailable',
              label: 'Hours available',
              name: 'hoursAvailable',
              placeholder: 'Type your available hours ',
              validate: requiredValidator,
            },
            {
              wrapper: StyledField,
              component: Input,
              id: 'travel',
              label: 'Can you travel internationally?',
              name: 'travel',
              placeholder: 'Type if you can travel internationally',
              validate: requiredValidator,
            },
            {
              wrapper: StyledField,
              component: Textarea,
              id: 'additionalComments',
              label: 'Additional comments',
              name: 'additionalComments',
              placeholder: 'Your additional comments',
            },
          ],
        },
        {
          title: tabTitles[6],
          fields: [
            {
              wrapper: StyledCastingFieldHalf,
              component: Dropzone,
              label: 'Photo 1 (face)',
              name: 'picture1',
              validate: requiredValidator,
            },
            {
              wrapper: StyledCastingFieldHalf,
              component: Dropzone,
              label: 'Photo 2 (face)',
              name: 'picture2',
              validate: requiredValidator,
            },
            {
              wrapper: StyledCastingFieldHalf,
              component: Dropzone,
              label: 'Photo 3 (full length)',
              name: 'picture3',
              validate: requiredValidator,
            },
            {
              wrapper: StyledCastingFieldHalf,
              component: Dropzone,
              label: 'Photo 4 (full length)',
              name: 'picture4',
              validate: requiredValidator,
            },
          ],
        },
      ]
      const initialValues = makeInitialValues(fields)

      return {
        fields,
        initialValues
      }
    },
    [isChecked, tabTitles, handleRadioChange],
  )
