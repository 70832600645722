import React, { useState } from 'react'
import { graphql } from 'gatsby'
import { Link, Element } from 'react-scroll'

import Layout from '../components/Layout'
import SEO from '../components/Seo'
import Container from '../components/Container'
import Inner from '../components/Inner'
import Button from '../components/Buttons/Button/Button'
import TextBox from '../components/TextBox/TextBox'
import MediaBox from '../components/MediaBox/MediaBox'
import CastingForm from '../components/Forms/CastingForm/CastingForm'
import TruncatedHero from '../components/Heros/TruncatedHero/TruncatedHero'
import {
  Heading7,
  Heading8,
  Heading9,
} from '../components/Typography/Typography'
import { TRUNCATED_HERO_TYPES } from '../constants/truncatedHeroTypes'
import Casting from '../components/Casting/Casting'
import Requirements from '../components/Requirements/Requirements'
import CincopaVideo from '../components/Video/CincopaVideo'
import { BG_TYPES } from '../constants/bgTypes'
import { BUTTON_SIZES } from '../constants/buttonSizes'
import VideoPlaceholder from '../components/VideoPlaceholder/VideoPlaceholder'
import HTMLBody from '../components/HTMLBody'

const CastingEscortJobPage = ({ data }) => {
  const [videoVisible, setVideoVisible] = useState(false)

  const page = data.wp.page?.castingAcf
  const seoData = data.wp.page?.seo

  const requirements = page.whoList?.map(({ feature }) => feature)

  const videoPlaceholder =
    page.videoImagePlaceholder?.imageFile?.childImageSharp.fluid

  const handleClickVideoVisible = () => setVideoVisible(true)

  return (
    <Layout>
      <SEO title={seoData?.title} data={seoData} />
      <Container bg={BG_TYPES.black}>
        <Container>
          <TruncatedHero
            header={
              <Heading8
                as="h1"
                color="white"
                dangerouslySetInnerHTML={{
                  __html: page.heroFields.title,
                }}
              />
            }
            action={
              <Button
                as={Link}
                btnSize={BUTTON_SIZES.small}
                aria-label="Scroll down"
                to="castingForm"
                href="#castingForm"
                spy
                smooth
              >
                Apply Now
              </Button>
            }
            heroImages={page.heroFields.bgImg.imageFile?.childImageSharp.fluid}
            positionImg="70% 0%"
            heroType={TRUNCATED_HERO_TYPES.withGradientOnMobile}
            gradient="top"
          />
        </Container>

        <Container mt={['-2rem', '0', '', '-6.625rem']}>
          <Casting
            bg={['black', '', 'darkGrey']}
            aboutHeader={
              <Heading9
                as="h2"
                color="white"
                dangerouslySetInnerHTML={{
                  __html: page.title,
                }}
              />
            }
            aboutContent={<HTMLBody color="white" html={page.desc} />}
            requirementsHeader={
              <Heading7 as="h2" color="yellow" fontWeight="semiBold">
                {page.whoTitle}
              </Heading7>
            }
            image={data.picCasting.childImageSharp.fluid}
          >
            <Requirements
              title={page.whoSubtitle}
              requirements={requirements}
            />
          </Casting>
        </Container>

        <Container bg={BG_TYPES.black}>
          <Container
            maxWidth={['', '', '80%']}
            ml="auto"
            mr="auto"
            pt={['', '', '1rem', '0']}
            mt={['', '', '', '-6.75rem']}
            zIndex="2"
            position="relative"
          >
            {videoPlaceholder && !videoVisible ? (
              <VideoPlaceholder
                image={videoPlaceholder}
                handleClickVideoVisible={handleClickVideoVisible}
              />
            ) : (
              <CincopaVideo id={page.videoCincopaId} />
            )}
          </Container>
        </Container>
      </Container>

      <Container
        bg={BG_TYPES.black}
        pt={['2.5rem', '3.5rem', '4.625rem']}
        pb={['2.5rem', '3.5rem', '3.125rem']}
      >
        <Inner>
          <TextBox content={<HTMLBody html={page.textContent} />} />
        </Inner>
      </Container>
      <Element name="castingForm">
        <Container zIndex={100} position="relative">
          <MediaBox
            image={data.picModel.childImageSharp.fluid}
            info={page.aboveFormInfo}
            hideImageOnMobile
            alt
          >
            <CastingForm titles={page.formTabs} />
          </MediaBox>
        </Container>
      </Element>
    </Layout>
  )
}

export default CastingEscortJobPage

export const query = graphql`
  query {
    picModel: file(relativePath: { eq: "pic-model.png" }) {
      childImageSharp {
        fluid(maxWidth: 620) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    picCasting: file(relativePath: { eq: "pic-casting.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 960) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    wp {
      page(id: "casting-escort-job", idType: URI) {
        seo {
          ...seoFragment
        }
        castingAcf {
          heroFields {
            title
            bgImg {
              mediaItemUrl
              imageFile {
                childImageSharp {
                  fluid(maxWidth: 1920) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
          title
          desc
          whoTitle
          whoSubtitle
          whoList {
            feature
          }
          videoCincopaId
          videoImagePlaceholder {
            mediaItemUrl
            imageFile {
              childImageSharp {
                fluid(maxWidth: 1600) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          textContent
          aboveFormInfo
          formTabs {
            tabTitle
          }
        }
      }
    }
  }
`
