import styled, { css } from 'styled-components'

import { transition } from '../../common/mixins'
import Container from '../Container'
import media from '../../common/MediaQueries'
import theme from '../../themes/theme'

const StyledAccordionButton = styled.button`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding-right: 2rem;
  color: ${({ theme }) => theme.colors.yellow};
  cursor: pointer;
  ${transition}

  &:hover {
    color: ${({ theme }) => theme.colors.white};
  }
`

const StyledAccordionButtonIcon = styled.span`
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  width: 1rem;
  height: 1rem;

  &::before,
  &::after {
    display: block;
    content: '';
    position: absolute;
    background: ${({ theme }) => theme.colors.yellow};
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    ${transition}
  }

  &::before {
    width: 1rem;
    height: 0.125rem;
  }

  &::after {
    width: 0.125rem;
    height: 1rem;
  }

  @media ${media.phone} {
    width: 1.25rem;
    height: 1.25rem;

    &::before {
      width: 1.25rem;
      height: 0.25rem;
    }

    &::after {
      width: 0.25rem;
      height: 1.25rem;
    }
  }
`

const StyledAccordionContent = styled.div`
  max-height: 0;
  overflow: hidden;
  visibility: hidden;

  ${({ isCollapsed }) =>
    !isCollapsed &&
    css`
      ${transition({ duration: '550ms', timingFunction: 'ease-in' })}
    `};
`

const StyledAccordion = styled(Container)`
  ${({ isCollapsed }) =>
    !isCollapsed &&
    css`
      ${StyledAccordionButton} {
        color: ${({ theme }) => theme.colors.white};
      }
      ${StyledAccordionButtonIcon} {
        &::after {
          opacity: 0;
          transform: translate(-50%, -50%) rotate(90deg);
        }
      }
      ${StyledAccordionContent} {
        max-height: 100rem;
        overflow: visible;
        visibility: visible;
      }
    `}

  ${({ hasError }) =>
    hasError &&
    css`
      color: ${theme.colors.error};
      ${StyledAccordionButton} {
        color: ${theme.colors.error};
      }
    `}
`

export {
  StyledAccordion,
  StyledAccordionContent,
  StyledAccordionButton,
  StyledAccordionButtonIcon,
}
