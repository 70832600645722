import React from 'react'
import PropTypes from 'prop-types'

import { StyledTextBox, StyledTextBoxColContent } from './styles'

const TextBox = ({ content }) => (
  <StyledTextBox>
    <StyledTextBoxColContent className="uiAnimBottom">
      {content}
    </StyledTextBoxColContent>
  </StyledTextBox>
)

export default TextBox

TextBox.propTypes = {
  content: PropTypes.node.isRequired,
}
