import React, { useState } from 'react'
import PropTypes from 'prop-types'
import _noop from 'lodash/noop'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image/withIEPolyfill'

import { StyledVideoPlaceholder, StyledVideoPlaceholderPlay } from './styles'
import Image from '../Image/Image'
import { fluidPropType } from '../../constants/proptypes'

const VideoPlaceholder = ({ image, vipImage, handleClickVideoVisible }) => {
  const [isVisible, setIsVisible] = useState(false)

  const play = useStaticQuery(
    graphql`
      query {
        picPlay: file(relativePath: { eq: "play.png" }) {
          childImageSharp {
            fluid(maxWidth: 150) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        picPlayHover: file(relativePath: { eq: "play-hover.png" }) {
          childImageSharp {
            fluid(maxWidth: 150) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `,
  )

  return (
    <StyledVideoPlaceholder>
      <Image fluid={image} />
      <StyledVideoPlaceholderPlay
        aria-label="Turn on the video"
        onClick={handleClickVideoVisible}
        onMouseEnter={() => setIsVisible(true)}
        onMouseLeave={() => setIsVisible(false)}
      >
        {vipImage ? (
          <img src={vipImage} alt="" />
        ) : (
          <Img
            fadeIn={false}
            loading="eager"
            fluid={
              isVisible
                ? play.picPlayHover.childImageSharp.fluid
                : play.picPlay.childImageSharp.fluid
            }
          />
        )}
      </StyledVideoPlaceholderPlay>
    </StyledVideoPlaceholder>
  )
}

export default VideoPlaceholder

VideoPlaceholder.defaultProps = {
  handleClickVideoVisible: _noop,
}

VideoPlaceholder.propTypes = {
  image: fluidPropType,
  vipImage: PropTypes.string,
  handleClickVideoVisible: PropTypes.func,
}
