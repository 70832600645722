import React, { useContext } from 'react'
import PropTypes from 'prop-types'

import HideImageContext from '../../contexts/HideImageContext'

import { StyledVideo, StyledVideoIframe } from './styles'

const Video = ({ src, ratio }) => {
  const { isInvisible } = useContext(HideImageContext)

  return (
    <StyledVideo ratio={ratio} isInvisible={isInvisible}>
      <StyledVideoIframe
        src={src}
        width="100%"
        height="100%"
        frameborder="0"
        allowfullscreen
        scrolling="no"
        allow="autoplay *; fullscreen *"
        title="Video"
      />
    </StyledVideo>
  )
}

export default Video

Video.defaultProps = {
  ratio: 36.375,
}

Video.propTypes = {
  ratio: PropTypes.number,
  src: PropTypes.string.isRequired,
}
