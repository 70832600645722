import React from 'react'
import PropTypes from 'prop-types'
import _noop from 'lodash/noop'

import {
  StyledAccordion,
  StyledAccordionContent,
  StyledAccordionButton,
  StyledAccordionButtonIcon,
} from './styles'

const Accordion = ({ title, children, handleClick, isClosed, hasError }) => (
  <StyledAccordion isCollapsed={isClosed} hasError={hasError}>
    <StyledAccordionButton
      aria-label="Open accordion"
      onClick={handleClick}
      type="button"
    >
      {title}
      <StyledAccordionButtonIcon />
    </StyledAccordionButton>
    <StyledAccordionContent isCollapsed={isClosed}>
      {children}
    </StyledAccordionContent>
  </StyledAccordion>
)

export default Accordion

Accordion.defaultProps = {
  isClosed: true,
  hasError: false,
  handleClick: _noop,
}

Accordion.propTypes = {
  title: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
  isClosed: PropTypes.bool,
  hasError: PropTypes.bool,
  handleClick: PropTypes.func,
}
