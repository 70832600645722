import React from 'react'
import PropTypes from 'prop-types'

import {
  StyledCasting,
  StyledCastingInner,
  StyledCastingAbout,
  StyledCastingAboutInner,
  StyledCastingAboutHeader,
  StyledCastingAboutDesc,
  StyledCastingRequirements,
  StyledCastingRequirementsInner,
  StyledCastingRequirementsHeader,
  StyledCastingImg,
} from './styles'
import Image from '../Image/Image'
import TextDecor from '../TextDecor/TextDecor'
import { fluidPropType } from '../../constants/proptypes'

const Casting = ({
  aboutHeader,
  aboutContent,
  requirementsHeader,
  image,
  children,
  bg,
}) => (
    <StyledCasting>
      <TextDecor label="casting" color="white" />
      <StyledCastingInner>
        <StyledCastingAbout bg={bg}>
          <StyledCastingAboutInner className="uiAnimBottom">
            <StyledCastingAboutHeader>{aboutHeader}</StyledCastingAboutHeader>
            <StyledCastingAboutDesc>{aboutContent}</StyledCastingAboutDesc>
          </StyledCastingAboutInner>
        </StyledCastingAbout>
        <StyledCastingRequirements>
          <StyledCastingRequirementsInner className="uiAnimBottom">
            <StyledCastingRequirementsHeader>
              {requirementsHeader}
            </StyledCastingRequirementsHeader>
            {children}
          </StyledCastingRequirementsInner>
        </StyledCastingRequirements>
      </StyledCastingInner>
      <StyledCastingImg>
        <Image fluid={image} objectPosition="100% 50%" />
      </StyledCastingImg>
    </StyledCasting>
  )

export default Casting

Casting.propTypes = {
  aboutHeader: PropTypes.node.isRequired,
  aboutContent: PropTypes.node.isRequired,
  requirementsHeader: PropTypes.node.isRequired,
  image: PropTypes.oneOfType([fluidPropType, PropTypes.arrayOf(fluidPropType)]),
  children: PropTypes.node.isRequired,
  bg: PropTypes.arrayOf(PropTypes.string),
}
