import React from 'react'
import PropTypes from 'prop-types'

import {
  StyledRequirements,
  StyledRequirementsDecorTopLeft,
  StyledRequirementsDecorTopRight,
  StyledRequirementsDecorBottomLeft,
  StyledRequirementsDecorBottomRight,
  StyledRequirementsHeader,
  StyledRequirementsContent,
  StyledRequirementsList,
  StyledRequirementsItem,
} from './styles'
import { Body } from '../Typography/Typography'
import DecorEdgeIcon from '../Icons/DecorEdgeIcon'

const Requirements = ({ title, requirements }) => (
  <StyledRequirements requirements={requirements}>
    <StyledRequirementsDecorTopLeft>
      <DecorEdgeIcon />
    </StyledRequirementsDecorTopLeft>
    <StyledRequirementsDecorTopRight>
      <DecorEdgeIcon />
    </StyledRequirementsDecorTopRight>
    <StyledRequirementsDecorBottomLeft>
      <DecorEdgeIcon />
    </StyledRequirementsDecorBottomLeft>
    <StyledRequirementsDecorBottomRight>
      <DecorEdgeIcon />
    </StyledRequirementsDecorBottomRight>
    <StyledRequirementsHeader>
      <Body fontWeight="bold" opacity="1">
        {title}
      </Body>
    </StyledRequirementsHeader>
    <StyledRequirementsContent>
      <StyledRequirementsList>
        {requirements.map((requirement) => (
          <StyledRequirementsItem key={requirement}>
            {requirement}
          </StyledRequirementsItem>
        ))}
      </StyledRequirementsList>
    </StyledRequirementsContent>
  </StyledRequirements>
)

export default Requirements

Requirements.propTypes = {
  title: PropTypes.string,
  requirements: PropTypes.arrayOf(PropTypes.string),
}
