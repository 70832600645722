import React, { useState } from 'react'
import { Form, Field } from 'react-final-form'
import PropTypes from 'prop-types'

import {
  StyledCastingFormWrapper,
  StyledCastingFormSection,
  StyledCastingFormAction,
  StyledCastingFormSentMsg,
  StyledCastingFormContent,
} from './styles'
import { StyledForm, StyledFormLoader } from '../styles'
import Container from '../../Container'
import Button from '../../Buttons/Button/Button'
import Accordion from '../../Accordion/Accordion'
import { Body, Typo3 } from '../../Typography/Typography'
import { BUTTON_TYPES } from '../../../constants/buttonTypes'
import { FIELD_TYPES } from '../../../constants/fieldTypes'
import { useFormFields } from './mock'
import { useOnSubmitHandler } from '../form'

const CastingForm = ({ titles }) => {
  const [isChecked, setIsChecked] = useState(true)
  const [accordionActive, setAccordionActive] = useState(0)

  const handleRadioChange = () => {
    setIsChecked(!isChecked)
  }

  const tabTitles = titles.map(({ tabTitle }) => tabTitle)

  const selectAccordion = (accordionNumber) => {
    const current = accordionActive === accordionNumber ? -1 : accordionNumber
    setAccordionActive(current)
  }

  const { fields, initialValues } = useFormFields(
    isChecked,
    tabTitles,
    handleRadioChange,
  )

  const { isLoading, isSent, onSubmit, message } = useOnSubmitHandler(
    'ajax_save_casting_submission',
  )

  return (
    <StyledCastingFormWrapper isSent={isSent}>
      <Form
        {...{ initialValues }}
        onSubmit={onSubmit}
        render={({ handleSubmit, values, errors, submitFailed }) => (
          <StyledForm onSubmit={handleSubmit} isLoading={isLoading}>
            {isSent ? (
              <StyledCastingFormSentMsg>
                <Body fontWeight="bold" color="success" opacity="1">
                  {message || 'Your message was sent successfully.'}
                </Body>
              </StyledCastingFormSentMsg>
            ) : (
              <>
                {submitFailed && !!errors && (
                  <Container bg="error" p="0.75rem 1rem" mb="0.75rem">
                    <Body color="white" opacity="1">
                      There was a problem with your submission. Errors have been
                      highlighted below.
                    </Body>
                  </Container>
                )}
                {fields.map(({ title, fields }, index) => (
                  <StyledCastingFormSection key={index}>
                    <Accordion
                      hasError={
                        submitFailed && fields.some((item) => errors[item.name])
                      }
                      isClosed={accordionActive !== index}
                      handleClick={() => selectAccordion(index)}
                      title={<Typo3 lineHeight="1.25">{title}</Typo3>}
                    >
                      <StyledCastingFormContent>
                        {fields.map(
                          ({
                            wrapper: Wrapper,
                            component: Component,
                            id,
                            name,
                            title,
                            label,
                            placeholder,
                            type,
                            options,
                            onChange,
                            disabled,
                            radioGroup,
                            initialValue,
                            validate,
                          }) => (
                            <Wrapper key={name}>
                              {radioGroup ? (
                                <Component
                                  fieldType={FIELD_TYPES.light}
                                  name={name}
                                  title={title}
                                  options={options}
                                  onChange={onChange}
                                />
                              ) : (
                                <Field
                                  initialValue={initialValue}
                                  name={name}
                                  validate={!!validate && validate(values)}
                                >
                                  {({ input, meta }) => (
                                    <Component
                                      fieldType={FIELD_TYPES.light}
                                      id={id}
                                      label={label}
                                      title={title}
                                      placeholder={placeholder}
                                      type={type}
                                      validate={meta.touched && meta.error}
                                      options={options}
                                      disabled={disabled}
                                      name={input.name}
                                      {...input}
                                    />
                                  )}
                                </Field>
                              )}
                            </Wrapper>
                          ),
                        )}
                      </StyledCastingFormContent>
                    </Accordion>
                  </StyledCastingFormSection>
                ))}
                <StyledCastingFormAction>
                  <Button
                    submit
                    type="submit"
                    aria-label="Submit"
                    btnType={BUTTON_TYPES.inverted}
                    disabled={isLoading}
                  >
                    Submit
                  </Button>
                </StyledCastingFormAction>
              </>
            )}
          </StyledForm>
        )}
      />
      {isLoading && <StyledFormLoader />}
    </StyledCastingFormWrapper>
  )
}

export default CastingForm

CastingForm.propTypes = {
  titles: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)),
}
