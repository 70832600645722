import styled, { css } from 'styled-components'

import { transition, decorLink, decorHoverLink } from '../../../common/mixins'
import { StyledFormLoader } from '../../Forms/styles'

const StyledDropzoneWrapper = styled.div`
  position: relative;
  color: ${({ theme }) => theme.colors.white};

  ${StyledFormLoader} {
    width: 2.5rem;
    height: 2.5rem;
    left: 3rem;

    &::after {
      border-width: 0.25rem;
    }
  }
`

const StyledDropzoneInner = styled.div`
  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.5;
      pointer-events: none;
    `}
`

const StyledDropzone = styled.div`
  &:focus {
    outline: 0;
  }
`

const StyledDropzoneLabel = styled.label`
  display: block;
  font-size: 0.875rem;
  margin-bottom: 0.5rem;

  ${({ validate }) =>
    validate &&
    css`
      color: ${({ theme }) => theme.colors.error};
    `}
`

const StyledDropzoneInput = styled.input``

const StyledDropzoneUpload = styled.button`
  text-transform: uppercase;
  font-size: 0.85rem;
  letter-spacing: 1px;
  font-family: ${({ theme }) => theme.fontFamilies.oswald};
  color: ${({ theme }) => theme.colors.yellow};
  cursor: pointer;
  ${decorLink};
  ${transition}

  &:hover {
    ${decorHoverLink};
  }
`

const StyledDropzoneFilesList = styled.ul`
  margin-top: 1rem;
`

const StyledDropzoneFileItem = styled.li`
  display: flex;
  color: ${({ theme }) => theme.colors.white};
`

const StyledDropzoneRemove = styled.button`
  margin-left: 1rem;
  margin-top: 0.3125rem;
  color: ${({ theme }) => theme.colors.error};
  cursor: pointer;

  svg {
    font-size: 0.625rem;
  }
`

export {
  StyledDropzoneWrapper,
  StyledDropzoneInner,
  StyledDropzone,
  StyledDropzoneLabel,
  StyledDropzoneInput,
  StyledDropzoneUpload,
  StyledDropzoneFilesList,
  StyledDropzoneFileItem,
  StyledDropzoneRemove,
}
