import styled from 'styled-components'
import { rgba } from 'polished'

import Container from '../Container'
import Inner from '../Inner'
import media, { customMedia } from '../../common/MediaQueries'

const StyledCasting = styled.div`
  position: relative;

  @media ${customMedia.maxPhone} {
    z-index: 1;
  }

  @media ${media.tablet} {
    &::after {
      display: block;
      width: 0;
      height: 0;
      border-style: solid;
      background: transparent;
      content: '';
      border-width: 13.5rem 0 0 100vw;
      border-color: transparent transparent transparent
        ${({ theme }) => theme.colors.black};
      position: absolute;
      right: 0;
      bottom: 0;
      z-index: 2;
    }
  }
`

const StyledCastingInner = styled(Inner)`
  padding-left: 0;
  padding-right: 0;

  @media ${media.tablet} {
    display: flex;
    padding-left: 1.875rem;
    padding-right: 1.875rem;
  }
`

const StyledCastingAbout = styled(Container)`
  padding: 0 1.875rem 9.25rem 1.875rem;
  position: relative;

  &::after {
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
    background: transparent;
    content: '';
    border-width: 7.75rem 0 0 100vw;
    border-color: transparent transparent transparent
      ${({ theme }) => theme.colors.white};
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 1;
  }

  @media ${media.tablet} {
    position: relative;
    width: 50%;
    padding: 5.375rem 2rem 17rem 0;
    margin-top: -6.5rem;
    z-index: 2;

    &::before {
      display: block;
      content: '';
      background: inherit;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      width: 50vw;
    }

    &::after {
      display: none;
    }
  }
`

const StyledCastingAboutInner = styled.div`
  @media ${media.tablet} {
    z-index: 2;
    position: relative;
    max-width: 28.125rem;
  }
`

const StyledCastingAboutHeader = styled.div`
  margin-bottom: 1.5rem;

  @media ${media.tablet} {
    margin-bottom: 3.125rem;
  }
`

const StyledCastingAboutDesc = styled.div`
  p + p {
    margin-top: 1.25rem;
  }
`

const StyledCastingRequirements = styled.div`
  position: relative;
  z-index: 2;
  background: ${({ theme }) => theme.colors.white};
  padding: 0 1.875rem 2.875rem 1.875rem;

  @media ${media.tablet} {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 8.5rem 3.5rem 5.5rem 2rem;
    background: transparent;
  }
`

const StyledCastingRequirementsInner = styled.div`
  @media ${media.tablet} {
    max-width: 26.25rem;
  }
`

const StyledCastingRequirementsHeader = styled.div`
  margin-bottom: 2.375rem;
`

const StyledCastingImg = styled.div`
  display: none;

  @media ${media.tablet} {
    display: block;
    position: absolute;
    top: 0;
    left: 50%;
    right: 0;
    bottom: 0;

    &::before {
      display: block;
      content: '';
      background-image: linear-gradient(
        to right,
        ${({ theme }) => rgba(theme.colors.white, 0.5)} 50%,
        transparent
      );
      z-index: 1;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
    }
  }
`

export {
  StyledCasting,
  StyledCastingInner,
  StyledCastingAbout,
  StyledCastingAboutInner,
  StyledCastingAboutHeader,
  StyledCastingAboutDesc,
  StyledCastingRequirements,
  StyledCastingRequirementsInner,
  StyledCastingRequirementsHeader,
  StyledCastingImg,
}
