import React from 'react'

import { IconWrapper } from '../../common/mixins'

const DecorEdgeIcon = () => (
  <IconWrapper width="1.125">
    <svg xmlns="http://www.w3.org/2000/svg">
      <g stroke="currentColor" fill="none" fillRule="evenodd">
        <path strokeWidth="4" d="M90 2H2v78" />
        <path strokeWidth="2" d="M77 9H9v58" />
      </g>
    </svg>
  </IconWrapper>
)

export default DecorEdgeIcon
