import styled from 'styled-components'
import { rgba } from 'polished'

import media from '../../../common/MediaQueries'
import {
  StyledFieldHalf,
  StyledFormContent,
  StyledFormSentMsg,
} from '../styles'

const StyledCastingFormWrapper = styled.div`
  position: relative;
  margin-bottom: 2.5rem;

  @media ${media.phone} {
    margin-bottom: 2rem;
  }
`

const StyledCastingFormSection = styled.div`
  overflow: hidden;
  margin-bottom: 0.625rem;
  background: ${({ theme }) => theme.colors.darkGrey};
  border: 1px solid ${({ theme }) => rgba(theme.colors.white, 0.15)};
  padding: 1rem;

  @media ${media.phone} {
    padding: 1rem 1.5rem;
  }
`

const StyledCastingFieldHalf = styled(StyledFieldHalf)`
  @media ${media.phone} {
    width: calc(50% - 0.5rem);
  }
`

const StyledCastingFormAction = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 2.5rem;

  @media ${media.phone} {
    justify-content: flex-end;
  }
`

const StyledCastingFormSentMsg = styled(StyledFormSentMsg)`
  padding: 2rem 0 3rem 0;
`

const StyledCastingFormContent = styled(StyledFormContent)`
  padding-bottom: 0.5rem;
`

export {
  StyledCastingFormWrapper,
  StyledCastingFormSection,
  StyledCastingFieldHalf,
  StyledCastingFormAction,
  StyledCastingFormSentMsg,
  StyledCastingFormContent,
}
