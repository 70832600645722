import styled from 'styled-components'
import { rgba } from 'polished'

import media, { customMedia } from '../../common/MediaQueries'

const StyledRequirements = styled.div`
  position: relative;
  padding: 2.875rem 2rem;

  @media ${media.phone} {
    padding: 2.125rem;
  }
`

const StyledRequirementsDecor = styled.span`
  color: ${({ theme }) => theme.colors.yellow};
  position: absolute;

  svg {
    font-size: 4.875rem;
  }
`

const StyledRequirementsDecorTopLeft = styled(StyledRequirementsDecor)`
  top: 0;
  left: 0;
`

const StyledRequirementsDecorTopRight = styled(StyledRequirementsDecor)`
  top: 0;
  right: 0;
  transform: rotate(90deg);
`

const StyledRequirementsDecorBottomLeft = styled(StyledRequirementsDecor)`
  bottom: 0;
  left: 0;
  transform: rotate(270deg);
`

const StyledRequirementsDecorBottomRight = styled(StyledRequirementsDecor)`
  bottom: 0;
  right: 0;
  transform: rotate(180deg);
`

const StyledRequirementsHeader = styled.div`
  margin-bottom: 0.75rem;

  @media ${customMedia.maxPhone} {
    display: none;
  }
`

const StyledRequirementsContent = styled.div`
  overflow: hidden;
`

const StyledRequirementsList = styled.ul`
  column-count: 2;
  column-gap: 1.5rem;
  margin-bottom: -0.0625rem;

  @media ${media.phone} {
    column-gap: 2.5rem;
  }

  @media ${media.tablet} {
    column-gap: 3.125rem;
  }
`

const StyledRequirementsItem = styled.li`
  position: relative;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  letter-spacing: 0.35px;
  font-size: 0.75rem;
  line-height: 3.3;
  border-bottom: 1px solid ${({ theme }) => rgba(theme.colors.black, 0.2)};
  padding-left: 0.875rem;

  &::before {
    display: block;
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 0.25rem;
    height: 0.25rem;
    border-radius: 100%;
    background: currentColor;
  }

  @media ${media.phone} {
    font-size: 0.875rem;
  }
`

export {
  StyledRequirements,
  StyledRequirementsDecorTopLeft,
  StyledRequirementsDecorTopRight,
  StyledRequirementsDecorBottomLeft,
  StyledRequirementsDecorBottomRight,
  StyledRequirementsHeader,
  StyledRequirementsContent,
  StyledRequirementsList,
  StyledRequirementsItem,
}
