import styled, { css } from 'styled-components'

const StyledVideo = styled.div`
  width: 100%;
  position: relative;
  padding-bottom: ${(props) => props.ratio}%;
  ${({ isInvisible }) =>
    isInvisible &&
    css`
      visibility: hidden;
    `}
`

const StyledVideoIframe = styled.iframe`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
`

export { StyledVideo, StyledVideoIframe }
