import styled from 'styled-components'

import media from '../../common/MediaQueries'

const StyledTextBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  color: ${({ theme }) => theme.colors.white};

  @media ${media.phone} {
    flex-direction: row;
    justify-content: space-between;
  }
`

const StyledTextBoxColContent = styled.div`
  width: 100%;

  p:first-of-type::first-letter {
    float: left;
    font-size: 3.125rem;
    color: ${({ theme }) => theme.colors.yellow};
    line-height: 0.85;
    padding-top: 0.25rem;
    padding-right: 0.5rem;
  }

  p + p {
    margin-top: 1.25rem;
  }

  &:not(:first-child) {
    margin-top: 1.25rem;
  }

  @media ${media.phone} {
    column-count: 2;
    column-gap: 4rem;

    p + p {
      margin-top: 1.5rem;
    }

    &:not(:first-child) {
      margin-top: 0;
    }
  }

  @media ${media.tablet} {
    column-gap: 6rem;
  }
`

export { StyledTextBox, StyledTextBoxColContent }
