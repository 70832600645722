import React from 'react'
import PropTypes from 'prop-types'

import Video from './Video'

const CincopaVideo = ({ id, ...props }) => (
  <Video
    src={`//www.cincopa.com/media-platform/iframe.aspx?fid=${id}`}
    {...props}
  />
)

CincopaVideo.propTypes = {
  ratio: PropTypes.number,
  id: PropTypes.string.isRequired,
}

export default CincopaVideo
