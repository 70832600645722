import React, { useCallback, useState, useContext } from 'react'
import axios from 'axios'

import { useDropzone } from 'react-dropzone'

import {
  StyledDropzoneWrapper,
  StyledDropzoneInner,
  StyledDropzone,
  StyledDropzoneLabel,
  StyledDropzoneInput,
  StyledDropzoneUpload,
  StyledDropzoneFilesList,
  StyledDropzoneFileItem,
  StyledDropzoneRemove,
} from './styles'
import RemoveIcon from '../../Icons/RemoveIcon'
import BaseApiUrlContext from '../../../contexts/BaseUrlApiContext'
import { StyledFormLoader } from '../../Forms/styles'

const Dropzone = ({ label, onChange, validate }) => {
  const [files, setFiles] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const baseApiUrl = useContext(BaseApiUrlContext)

  const onDrop = async (acceptedFiles) => {
    const newFile = acceptedFiles.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
      }),
    )

    setFiles(newFile)

    const formData = new FormData()
    formData.append('action', 'ajax_handle_fileupload')
    formData.append('file', newFile[0])

    setIsLoading(true)

    try {
      const response = await axios({
        method: 'post',
        url: baseApiUrl,
        data: formData,
      })

      if (onChange) {
        onChange(response?.data?.data.url)
      }
    } catch (error) {
      console.log(error)
    }

    setIsLoading(false)
  }

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: false,
    accept: 'image/jpeg, image/png',
  })

  const removeFile = useCallback(
    (e) => {
      const { attr } = e.currentTarget.dataset
      const newFiles = files.filter((file) => file.path !== attr)
      setFiles(newFiles)
      onChange(newFiles.length ? newFiles[0] : undefined)
    },
    [onChange, files],
  )

  return (
    <StyledDropzoneWrapper>
      {isLoading && <StyledFormLoader />}
      <StyledDropzoneInner disabled={isLoading}>
        <StyledDropzone {...getRootProps()}>
          <StyledDropzoneInput {...getInputProps()} />
          <StyledDropzoneLabel validate={!!validate}>
            {label}
          </StyledDropzoneLabel>
          <StyledDropzoneUpload aria-label="Upload file" type="button">
            Select files
          </StyledDropzoneUpload>
        </StyledDropzone>
        {files.length > 0 && (
          <StyledDropzoneFilesList>
            {files.map(({ path }) => (
              <StyledDropzoneFileItem key={path}>
                <span>{path}</span>
                <StyledDropzoneRemove
                  data-attr={path}
                  aria-label="Remove file"
                  type="button"
                  onClick={removeFile}
                >
                  <RemoveIcon />
                </StyledDropzoneRemove>
              </StyledDropzoneFileItem>
            ))}
          </StyledDropzoneFilesList>
        )}
      </StyledDropzoneInner>
    </StyledDropzoneWrapper>
  )
}

export default Dropzone
