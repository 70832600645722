import styled from 'styled-components'

import media from '../../common/MediaQueries'

const StyledVideoPlaceholder = styled.div`
  position: relative;
`

const StyledVideoPlaceholderPlay = styled.button`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  .gatsby-image-wrapper {
    width: 4rem;
  }

  @media ${media.phone} {
    .gatsby-image-wrapper {
      width: 7rem;
    }
  }

  @media ${media.tablet} {
    .gatsby-image-wrapper {
      width: 9rem;
    }
  }
`

export { StyledVideoPlaceholder, StyledVideoPlaceholderPlay }
